import React, { Component } from 'react';
import '../../assets/css/base/header.css'
import '../../assets/css/mobile/mobile.css'
import '../../assets/css/mobile/mobile_320.css'
import '../../assets/css/desktop/desktop.css'
import '../../assets/css/tablet/tablet.css';
import appstore from '../../assets/img/btn-app-store@3x.png';
import googleplay from '../../assets/img/btn-google-play@3x.png';
import tocha from '../../assets/img/tosha-profile@2x.png';

function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
      return "Android";
    }
    // iOS detection from: https://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
    return "unknown";
  }

function DetectAndServe() {
  if (getMobileOperatingSystem() == "Android") {
    window.location.href = "https://play.google.com/store/apps/details?id=com.intermindco.maumbot";
  }
  if (getMobileOperatingSystem() == "iOS") {
    window.location.href = "itms-apps://itunes.apple.com/app/id1441749378";
  }
  if (getMobileOperatingSystem() == "Windows Phone") {
    window.location.href = "https://play.google.com/store/apps/details?id=com.intermindco.maumbot";
  }
  if (getMobileOperatingSystem() == "unknown") {
    window.location.href = "https://play.google.com/store/apps/details?id=com.intermindco.maumbot";
  }
};



class Header extends Component {
    render() {
        return(    
            <div className="size">
            <div className="headersection"> 
                <div className="content">
                    <div className="title">
                    <p className="tocha_header_title"> 
                    토샤 타로보는 사막여우 챗봇</p>
                    <p className="tocha_header_title_mobile"> 
                    토샤 <br/>타로보는 사막여우</p>
                    </div>
                    <div className="contents">
                    <p className="tocha_header_contents">
                    재미있게, 즐겁게, 행복하게, <br />
                    사막여우 토샤가 들려주는 타로 이야기 </p>
                    </div>
                    <div className="app_download" onClick={DetectAndServe}>
                        <div className="btn_app">
                        <div className="btn_text">토샤 앱 다운로드</div>
                        </div>
                    </div>

                    <div className="appdownlaod">
                    <a href="https://itunes.apple.com/app/id1441749378">
                        <img src={appstore} className="apple_btn" alt="앱스토어"></img></a>
                    <a href="https://play.google.com/store/apps/details?id=com.intermindco.maumbot">
                        <img src={googleplay} className="google_btn" alt="구글플레이스토어" /></a>
                    </div>
                    <div className="char">
                         <img src={tocha} />
                    </div>
                </div>

                <div className="app_download_mobile" onClick={DetectAndServe}>
                        <div className="btn_app">
                        <div className="btn_text">토샤 앱 다운로드</div>
                        </div>
                    </div>


            </div>
            </div>
           
    )
    }
}

export default Header;