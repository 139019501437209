import React, { Component } from 'react';
import '../../assets/css/base/section2.css'
import '../../assets/css/mobile/mobile.css'
import '../../assets/css/mobile/mobile_320.css'
import '../../assets/css/desktop/desktop.css'
import '../../assets/css/tablet/tablet.css';
import tocha from '../../assets/img/c-2-phone@3x.png';

class Section2 extends Component {
    render() {
        return (
            <div className="section2">
                <div className="section2_content">
                    <div className="section2_left">
                    <div className="section2_title">
                        <p>
                        유쾌한 토샤의 말솜씨와  <br />
                        다양한 종류의 무료 타로!
                        </p>
                    </div>
                    <div className="section2_title_mobile">
                        <p>
                        유쾌한 토샤의 말솜씨와  <br />
                        다양한 종류의  <br/> 
                        무료 타로!
                        </p>
                    </div>
                    <div className="section2_text">
                        <p>
                        토샤는 수십만개의 대화 시나리오가 <br />
                        학습되어 있어 실제 친구처럼 다양한 <br />
                        말솜씨를 뽐낼 수 있어요!<br />
                        <br />
                        또한, 연애타로, 선택의 타로 등 열여덟 종류의 <br />
                        다양한 타로를 볼 준비도 되어 있답니다. <br />
                        토샤의 타로 종류는 계속해서 추가 될 예정입니다. <br />
                        </p>
                    </div>
                    <div className="section2_tablet">
                        <p>
                        토샤는 수십만개의 대화 시나리오가 <br />
                        학습되어 있어 실제 친구처럼 다양한 말솜씨를 <br />
                        뽐낼 수 있어요!<br />
                        <br />
                        또한, 연애타로, 선택의 타로 등 열여덟 종류의 <br />
                        다양한 타로를 볼 준비도 되어 있답니다. 토샤의 타로 종류는 <br />
                        계속해서 추가 될 예정입니다. 
                        </p>
                    </div>
                    </div>
                    <div className="section_right">
                    <div className="tocha_section2">
                    <img className="tocha_1" src={tocha}/>
                    </div>
                    </div>
                    <div className="tocha_section1_tablet">
                    <img className="tocha_1" src={tocha}/>
                    </div>
                    
                </div>
                    
            </div>
        );
    }
}

export default Section2;