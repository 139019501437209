import React, { Component } from 'react';
import Header from "./Header";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Section7 from "./Section7";
import Section8 from "./Section8";
import '../../assets/css/base/review.css';
import Footer from "./Footer";

class index extends Component {
    render() {
        return (
            <div>
               <Header/>
                  <Section1/>
                  <Section2/>
                  <Section3/>
                  <Section4/>
                  <Section5/>
                  <Section6/> 
                  <div className="review_tocha">
                  <Section7/>   
                  <Section8/>       
                  </div>
                  <Footer/>          
            </div>
        );
    }
} 

export default index;