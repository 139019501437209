import React, { Component } from 'react';
import '../../assets/css/base/section3.css'
import '../../assets/css/mobile/mobile.css'
import '../../assets/css/mobile/mobile_320.css'
import '../../assets/css/desktop/desktop.css'
import '../../assets/css/tablet/tablet.css';
import tocha from '../../assets/img/c-3-phone@3x.png';

class Section3 extends Component {
    render() {
        return (
            <div className="section3">
                <div className="section3_content">
                    <div className="section3_left">
                    <div className="section3_title">
                        <p>
                        10가지 연애 주제로 <br />
                        상담 가능한 연애 타로!
                        </p>
                    </div>
                    <div className="section3_text">
                        <p>
                        관계흐름, 궁합, 화해여부, 속마음, 이별여부 등 <br />
                        10가지의 주제로 토샤가 타로 상담을 해요. <br />
                        그 사람의 마음이 궁금하다면 빨리 와서 <br />
                        토샤에게 물어보세요!
                        </p>
                    </div>
                    <div className="section3_tablet">
                        <p>
                        관계흐름, 궁합, 화해여부, 속마음, 이별여부 등 <br />
                        10가지의 주제로 토샤가 타로 상담을 해요. <br />
                        그 사람의 마음이 궁금하다면 빨리 와서 토샤에게 물어보세요!<br />
                        </p>
                    </div>

                    <div className="section3_mobile">
                        <p>
                        관계흐름, 궁합, 화해여부, 속마음, 이별여부 등 <br />
                        10가지의 주제로 토샤가 타로 상담을 해요. <br />
                        그 사람의 마음이 궁금하다면 빨리 와서 <br />
                        토샤에게 물어보세요!<br />
                        </p>
                    </div>


                    </div>
                    <div className="section_right">
                    <div className="tocha_section3">
                    <img className="tocha_3" src={tocha}/>
                    </div>
                    </div>
                    <div className="tocha_section1_tablet">
                    <img className="tocha_3" src={tocha}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Section3