import React, { Component } from 'react';
import '../../assets/css/base/section1.css'
import '../../assets/css/mobile/mobile.css'
import '../../assets/css/mobile/mobile_320.css'
import '../../assets/css/desktop/desktop.css'
import '../../assets/css/tablet/tablet.css';
import tocha from '../../assets/img/c-1-img@3x.png';

class Section1 extends Component {
    render() {
        return (
            <div className="section1">
                <div className="section1_content">
                    <div className="section1_left">
                    <div className="section1_title">
                        <p>
                        고민을 해결해주는 비밀친구, <br />
                        타로 마스터 토샤! 
                        </p>
                    </div>
                    <div className="section1_text">
                        <p>
                        큰 귀를 이용해 세심하게 <br />
                        고민 상담을 해주는 사막여우! <br />
                        <br />
                        사막에서 생활하는 타로 마스터들에게 수년간 <br /> 
                        타로 교육을 받고, 현재는 사막을 떠돌아 다니며 <br /> 
                        지나가는 행인들에게 타로를 봐주고 있어요.<br /> 
                        <br />
                        토샤와 지금 바로 타로 상담을 해보세요!<br /> 
                        아참, 토샤와의 상담 내용은 둘만 아는 비밀! 
                        </p>
                    </div>

                    <div className="tocha_section1_mobile">
                    <img className="tocha_taro" src={tocha}/>
                    </div>


                    </div>
                    <div className="section_right">
                    <div className="tocha_section1">
                    <img className="tocha_taro" src={tocha}/>
                    </div>
                    </div>
                    <div className="tocha_section1_tablet">
                    <img className="tocha_taro" src={tocha}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Section1;