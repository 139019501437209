import React, { Component } from 'react';
import '../../assets/css/base/section6.css'
import '../../assets/css/mobile/mobile.css'
import '../../assets/css/mobile/mobile_320.css'
import '../../assets/css/desktop/desktop.css'
import '../../assets/css/tablet/tablet.css';
import tocha from '../../assets/img/c-6-phone@3x.png';

class Section6 extends Component {
    render() {
        return (
            <div className="section6">
                <div className="section6_content">
                    <div className="section6_left">
                    <div className="section6_title">
                        <p>
                        나의 성향을 알아 볼 수 있는 소울카드!
                        </p>
                    </div>
                    <div className="section6_text">
                        <p>
                        생년월일을 통해 나만의 소울카드를 확인해보세요. <br/>
                        나의 성향을 단숨에 파악할 수 있어요!
                        </p>
                    </div>
                    <div className="section6_text_mobile">
                        <p>
                        생년월일을 통해 <br/>
                        나만의 소울카드를 확인해보세요. <br/>
                        나의 성향을 단숨에 파악할 수 있어요!
                        </p>
                    </div>
                    </div>
                    <div className="section_right">
                    <div className="tocha_section6">
                    <img className="tocha_6" src={tocha}/>
                    </div>
                    </div>
                    <div className="tocha_section6_tablet">
                    <img className="tocha_6" src={tocha}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Section6