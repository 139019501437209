import React, { Component } from 'react';
import reviewbg from '../../assets/img/review-bg-one@2x.png';
import '../../assets/css/base/section8.css'
import '../../assets/css/mobile/mobile.css'
import '../../assets/css/mobile/mobile_320.css'
import '../../assets/css/desktop/desktop.css'
import '../../assets/css/tablet/tablet.css';




class Section8 extends Component {
    render() {
        return (
            <div>
                <div className="reviewbg"/>
                <div className="reviewbg_mobile"/>              
            </div>
        );
    }
}

export default Section8;