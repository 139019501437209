import React, { Component } from 'react';
import '../../assets/css/base/footer.css'
import '../../assets/css/mobile/mobile.css'
import '../../assets/css/mobile/mobile_320.css'
import '../../assets/css/desktop/desktop.css'
import '../../assets/css/tablet/tablet.css';
import instagram from'../../assets/img/ic-instagram@3x.png'
import facebook from '../../assets/img/ic-facebook@3x.png'

class Footer extends Component {
    render() {
        return (
            <div className="footer">
              <div className="footer-contents">

              {/* desktop mode */}
              <div className="footer_desktop">
              <div className="footer_left">
              <div className="footer_text">
                    <p className="copyright">
                    MODITT CORPORATION <br/>
                    COPYRIGHT MODITT CORP. ALL RIGHTS REVERSED.</p>
                    <p className="office">
                    기업부설연구소 및 벤처 인증기업</p>

                    <p className="sns">
                    <img src={facebook} className="facebook"/>
                    <img src={instagram} className="instagram"/>
                    </p>

                    <p className="font_arita">
                    이 제작물은 아모레퍼시픽의 아리따글꼴을 사용하여 디자인 되었습니다.</p>

                    </div>
                    
              </div>
             

        <div className ="footer_right">
            <div className="adress_title">
                <p>CALL</p>
                <p>CONTACT</p>
                <p>ADRESS</p>        
            </div>

            <div className="adress_info">
                <p>070-4518-9983</p>
                <p>TOCHA@TAROTMOON.CO.KR</p>
                <p>서울시 강남구 논현로98길 28 306호</p>        
            </div>
        </div>
        </div>


          {/* tablet mode */}
          <div className="footer_tablet">
          <div className="footer_left">
              <div className="footer_text">
                    <p className="copyright">
                    MODITT CORPORATION <br/>
                    COPYRIGHT MODITT CORP. ALL RIGHTS REVERSED.</p>
                    <p className="office">
                    기업부설연구소 및 벤처 인증기업</p>


            <div className ="footer_right">
                <div className="adress_title">
                    <p>CALL</p>
                    <p>CONTACT</p>
                    <p>ADRESS</p>        
                </div>

                <div className="adress_info">
                    <p>070-4518-9983</p>
                    <p>TOCHA@TAROTMOON.CO.KR</p>
                    <p>서울시 강남구 논현로98길 28 306호</p>        
                </div>
             </div>


                    <p className="sns">
                    <img src={facebook} className="facebook"/>
                    <img src={instagram} className="instagram"/>
                    </p>
                    <div className="font_arita">
                    이 제작물은 아모레퍼시픽의 아리따글꼴을 사용하여 디자인 되었습니다.</div>

                    </div>
                    
              </div>
    
       
        </div>

            </div>
            </div>
        );
    }
}

export default Footer