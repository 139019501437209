import React from 'react';
import Index from "../src/page/Main/index"

function App() {
  return (
    <Index/>
  );
}

export default App;
