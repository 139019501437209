import React, { Component } from 'react';
import '../../assets/css/base/section4.css'
import '../../assets/css/mobile/mobile.css'
import '../../assets/css/mobile/mobile_320.css'
import '../../assets/css/desktop/desktop.css'
import '../../assets/css/tablet/tablet.css';
import tocha from '../../assets/img/c-4-phone@3x.png';

class Section4 extends Component {
    render() {
        return (
            <div className="section4">
                <div className="section3_content">
                    <div className="section4_left">
                    <div className="section3_title">
                        <p>
                        하루 일상을 들여다 <br/>
                        볼 수 있는 오늘의 타로!
                        </p>
                    </div>
                    <div className="section3_text">
                        <p>
                        일상 타로, 금전 타로부터 <br/>
                        행운의 장소, 색깔, 물건까지!<br/>
                        오늘 하루를 들여다 보고 힘찬 하루를 시작해보세요.
                        </p>
                    </div>
                    <div className="section4_tablet">
                        <p>
                        일상 타로, 금전 타로부터 행운의 장소, 색깔, 물건까지!<br/>
                        오늘 하루를 들여다 보고 힘찬 하루를 시작해보세요.
                        </p>
                    </div>
                    <div className="section4_mobile">
                        <p>
                        일상 타로, 금전 타로부터 <br/>
                        행운의 장소, 색깔, 물건까지!<br/>
                        오늘 하루를 들여다 보고 힘찬 하루를 <br/>
                        시작해보세요.
                        </p>
                    </div>
                    </div>
                    <div className="section_right">
                    <div className="tocha_section4">
                    <img className="tocha_4" src={tocha}/>
                    </div>
                    </div>
                    <div className="tocha_section1_tablet">
                    <img className="tocha_4" src={tocha}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Section4