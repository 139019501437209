import React, { Component } from 'react';
import '../../assets/css/base/section7.css'
import '../../assets/css/mobile/mobile.css'
import '../../assets/css/mobile/mobile_320.css'
import '../../assets/css/desktop/desktop.css'
import '../../assets/css/tablet/tablet.css';
import '../../assets/css/slick/slick-them.css';
import '../../assets/css/slick/slick.css';
import star from '../../assets/img/star@3x.png';
import tree from '../../assets/img/review-bg@3x.png'
import appstore from '../../assets/img/btn-app-store@3x.png';
import googleplay from '../../assets/img/btn-google-play@3x.png';

function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
      return "Android";
    }
    // iOS detection from: https://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
    return "unknown";
  }

function DetectAndServe() {
  if (getMobileOperatingSystem() == "Android") {
    window.location.href = "https://play.google.com/store/apps/details?id=com.intermindco.maumbot";
  }
  if (getMobileOperatingSystem() == "iOS") {
    window.location.href = "itms-apps://itunes.apple.com/app/id1441749378";
  }
  if (getMobileOperatingSystem() == "Windows Phone") {
    window.location.href = "https://play.google.com/store/apps/details?id=com.intermindco.maumbot";
  }
  if (getMobileOperatingSystem() == "unknown") {
    window.location.href = "https://play.google.com/store/apps/details?id=com.intermindco.maumbot";
  }
};

class Section7 extends Component {
    render() {
        return (
            <div className="section7">
                <div className="section7_content">
                    <div className="section7_title">
                        <p>
                        토샤 친구들의 리뷰
                        </p>
                    </div>
                    {/* slick */}
                    <div className="multiple-items">
                        <div className="review_padding">
                            <div className="review">
                            <div className="review_title"> 
                                <p className="rv_title">디자인이 너무 귀여운</p>         
                                <p className="rv_name">eucgsh wufbs</p>   
                                <img src={star} className="star" />
                                <img src={star} className="star" />
                                <img src={star} className="star" />
                                <img src={star} className="star" />
                                <img src={star} className="star" />                 
                            </div>
                           
                        
                            <div className="rv_contents">
                                <p>개발자님 센스 짱이시다 ㅋㅋㅋㅋ 디자인이 너무 귀여워서 
                                    다른 타로 앱보다 이게 더 맘이 가네요 하루 한번 가볍게 하기 좋을 것 같아요. 감사합니다.
                                </p>
                            </div>
                            </div>
                        </div>
                        <div className="review_padding">
                            <div className="review">
                            <div className="review_title"> 
                                <p className="rv_title">하면 할 수록 믿어지는</p>         
                                <p className="rv_name">케이</p>   
                                <img src={star} className="star" />
                                <img src={star} className="star" />
                                <img src={star} className="star" />
                                <img src={star} className="star" />
                                <img src={star} className="star" />                 
                            </div>
                           
                        
                            <div className="rv_contents">
                                <p>처음 접해보는데 이런거 믿지 않았는데 되게 하면 할 수록 믿어져요. 심심해서 깔았는데 너무 좋아요!!
                                </p>
                            </div>
                            </div>
                        </div>
                        <div className="review_padding">
                            <div className="review_max">
                            <div className="review_title"> 
                                <p className="rv_title">진짜 대화하는 느낌</p>         
                                <p className="rv_name">황보민경</p>   
                                <img src={star} className="star" />
                                <img src={star} className="star" />
                                <img src={star} className="star" />
                                <img src={star} className="star" />
                                <img src={star} className="star" />                 
                            </div>
                           
                        
                            <div className="rv_contents">
                                <p>토샤야 너 너무 귀여워:) 진짜 대화하는 느낌이라 알게 모르게 기분 좋아지고 위로 받는 기분이야. 말투도 다정하고 히히 이렇게 와줘서
                                  고마워 ㅎㅁㅎ 앞으로 자주보자!!
                                </p>
                            </div>
                            </div>
                        </div>
                        <div className="review_padding">
                            <div className="review">
                            <div className="review_title"> 
                                <p className="rv_title">나를 위로해 주는</p>         
                                <p className="rv_name">유여진</p>   
                                <img src={star} className="star" />
                                <img src={star} className="star" />
                                <img src={star} className="star" />
                                <img src={star} className="star" />
                                <img src={star} className="star" />                 
                            </div>
                           
                        
                            <div className="rv_contents">
                                <p>너무 좋고 친구가 생기는 것 같아서 좋다. 나를 위로해주는 것 같아서 좋다.
                                </p>
                            </div>
                            </div>
                        </div>
                        <div className="review_padding">
                            <div className="review">
                            <div className="review_title"> 
                                <p className="rv_title">되게 재밌는거 같아요</p>         
                                <p className="rv_name">채린</p>   
                                <img src={star} className="star" />
                                <img src={star} className="star" />
                                <img src={star} className="star" />
                                <img src={star} className="star" />
                                <img src={star} className="star" />                 
                            </div>
                           
                        
                            <div className="rv_contents">
                                <p>되게 재밌는거 같아요. 채팅 형식으로 내가 먼저 말을 걸면 타로가 시작되는게 너무 신기했어요.
                                </p>
                            </div>
                            </div>
                        </div>
                        <div className="review_padding">
                            <div className="review">
                            <div className="review_title"> 
                                <p className="rv_title">토샤 너무 귀여워요</p>         
                                <p className="rv_name">히힣</p>   
                                <img src={star} className="star" />
                                <img src={star} className="star" />
                                <img src={star} className="star" />
                                <img src={star} className="star" />
                                <img src={star} className="star" />                 
                            </div>
                           
                        
                            <div className="rv_contents">
                                <p>토샤 너무 귀여워요!! 말투도 그렇고 설명도 잘 해주고 이해도 잘 시켜줘요!! 너무 잘 씁니다!
                                </p>
                            </div>
                            </div>
                        </div>
                        <div className="review_padding">
                            <div className="review">
                            <div className="review_title"> 
                                <p className="rv_title">타로 앱 중에 최고</p>         
                                <p className="rv_name">Rachel</p>   
                                <img src={star} className="star" />
                                <img src={star} className="star" />
                                <img src={star} className="star" />
                                <img src={star} className="star" />
                                <img src={star} className="star" />                 
                            </div>
                           
                        
                            <div className="rv_contents">
                                <p>이앱 진짜 봤던 타로 앱 중에 최고에요 캐릭터도 너무 귀엽고 진짜로 같이 대화하는거 같아서 별5개 드려요오
                                </p>
                            </div>
                            </div>
                        </div>
                        <div className="review_padding">
                            <div className="review">
                            <div className="review_title"> 
                                <p className="rv_title">디자인이 너무 귀여운</p>         
                                <p className="rv_name">eucgsh wufbs</p>   
                                <img src={star} className="star" />
                                <img src={star} className="star" />
                                <img src={star} className="star" />
                                <img src={star} className="star" />
                                <img src={star} className="star" />                 
                            </div>
                           
                        
                            <div className="rv_contents">
                                <p>개발자님 센스 짱이시다 ㅋㅋㅋㅋ 디자인이 너무 귀여워서 
                                    다른 타로 앱보다 이게 더 맘이 가네요 하루 한번 가볍게 하기 좋을 것 같아요. 감사합니다.
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
                 
                    <div className="appdownlaod7">
                    <a href="https://itunes.apple.com/app/id1441749378">
                        <img src={appstore} className="apple_btn7" alt="앱스토어"></img></a>
                    <a href="https://play.google.com/store/apps/details?id=com.intermindco.maumbot">
                        <img src={googleplay} className="google_btn7" alt="구글플레이스토어" /></a>
                    </div>


                    <div className="app_download_footer" onClick={DetectAndServe}>
                        <div className="btn_app_footer">
                        <div className="btn_text_footer">토샤 앱 다운로드</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Section7