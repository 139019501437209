import React, { Component } from 'react';
import '../../assets/css/base/section5.css'
import '../../assets/css/mobile/mobile.css'
import '../../assets/css/mobile/mobile_320.css'
import '../../assets/css/desktop/desktop.css'
import '../../assets/css/tablet/tablet.css';
import tocha from '../../assets/img/c-5-phone@3x.png';

class Section5 extends Component {
    render() {
        return (
            <div className="section5">
                <div className="section5_content">
                    <div className="section5_left">
                    <div className="section5_title">
                        <p>
                        결정을 망설이는 친구들에게 <br/>
                        도움 되는 선택의 타로!
                        </p>
                    </div>
                    <div className="section5_title_mobile">
                        <p>
                        결정을 망설이는 <br/>
                        친구들에게 도움 되는 
                        <br/>선택의 타로!
                        </p>
                    </div>
                    <div className="section5_text">
                        <p>
                        하루에도 몇번씩 하는 수많은 고민들<br/>
                        선택의 타로가 친구들의 선택을 도와줄거에요!
                        </p>
                    </div>
                    </div>
                    <div className="section_right">
                    <div className="tocha_section5">
                    <img className="tocha_5" src={tocha}/>
                    </div>
                    </div>
                    <div className="tocha_section1_tablet">
                    <img className="tocha_5" src={tocha}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Section5